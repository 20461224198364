<template>
  <div class="about"   ref="about" >
    <!-- <div class="top">
      <div class="left"><img src="../assets/img/index/title.png" alt=""></div>
      <div class="right"><img src="../assets/img/index/nav.png" alt=""></div>
    </div>
    <div class="swiper_content">
      <div class="swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="item in photo1" :key="item" >
            <img :src=" require('../assets/img/index/'+item+'.jpg')" alt="">
          </div>
        </div>
        <div class="swiper-pagination"></div>
    </div>
    </div>
    <div class="kong"></div>
    <div class="nav">
      <div class="nav_item1">
        <div class="img"><img src="../assets/img/index/index.png" alt=""></div>
        <div class="text">首页</div>
      </div>
      <div class="nav_item2">
        <div class="img"><img src="../assets/img/index/about.png" alt=""></div>
        <div class="text">关于我们</div>
      </div>
      <div class="nav_item3">
        <div class="img"><img src="../assets/img/index/technology.png" alt=""></div>
        <div class="text">核心技术</div>
      </div>
      <div class="nav_item4">
        <div class="img"><img src="../assets/img/index/cooperate.png" alt=""></div>
        <div class="text">项目合作</div>
      </div>
    </div> -->
    <Header></Header>
    <div class="toTop" v-show="topBtnShow" @click="scrollToTop"></div>
    <div class="kong"></div>
    <div class="about_detail">
      <div class="title">北京红本科技有限公司</div>
      <div class="icons"><img src="../assets/img/about/icons.png" alt=""></div>
      <div class="text1">
        <div class="text_tit">
          <div class="tip"><img src="../assets/img/index/oneForOne_tip.png" alt=""></div>
          <div class="txt">公司介绍</div>
        </div>
        <div class="text_box">
          <p>北京红本科技有限公司，隶属于北京国音红杉树智能科技有限公司，是一家集人工智能技术研发、大数据开发运用、智能化教学研究、市场运营管理为一体的科技公司。总部位于北京市海淀区，在洛阳成立运营中心、直营样板市场等。红杉树小红本为其旗下品牌，拥有核心技术记忆引擎。</p>
          <p>2001年，杨宁远博士在美国硅谷发明记忆引擎技术，2010年公司独家引入记忆引擎，开启英语智能化自主学习新时代。</p>
          <p>为了能让乡镇、教育资源匮乏地区的孩子能够享用到智能化学习方式，2019年小红本项目立项，经过3年迭代升级于2021年推向全国市场。小红本搭载全新记忆引擎核心技术，为孩子们学习英语提供沉浸式学习环境，打造智能英语自主学习方案。</p>
          <p>2016年7月公司被吸纳为中国智慧工程研究会基础教育专业委员会理事单位。同年，红杉树智能英语“ECC智能英语课堂的研究与应用”被列入十三五教育规划重点科研课题。2021年，“ECC智能英语教学模式的研究与应用”被列为十四五教育科研规划重点课题。</p>
          <p>2016年至今公司连续获得国家高新技术企业荣誉称号，2017年2月公司获得中关村高新技术企业荣誉称号。公司目前已获得5项专利和39项软件著作权。</p>
        </div>
      </div>
      <div class="text2">
        <div class="text_tit">
          <div class="tip"><img src="../assets/img/index/oneForOne_tip.png" alt=""></div>
          <div class="txt">全国合作伙伴会议</div>
        </div>
        <div class="text_box">
          <img src="../assets/img/about/all.png" alt="">
        </div>
      </div>
    </div>
    <div class="kong"></div>
    <div class="all_year">
      <div class="title">发展历程</div>
      <div class="top">
        <div class="swiper-container1">
          <div class="swiper-wrapper">
            <div class="swiper-slide num" v-for="(item, index) in yearList" :key="index" :id="item.id"  >
              {{ item.year }}
            </div>
          </div>
        </div>
      </div>
      <div class="next" @click="next()"><img src="../assets/img/about/next.png" alt=""></div>
        <div class="prev" @click="prev()"><img src="../assets/img/about/prev.png" alt=""></div>
      <div class="content">
        
        <div class="swiper_content">
        <div class="swiper-2">
          <div class="swiper-wrapper">
            <div class="swiper-slide " v-for="item in imgList_1" :key="item.url">
              <img :src=" require('../assets/img/'+item.url)" alt="">
              <div class="swiper_tit">{{item.title}}</div>
              <div class="swiper-2-text">
                {{item.text}}
              </div>
            </div>
          </div>
        </div>

        </div>
        
      </div>
    </div>
    <div class="kong"></div>
    <div class="culture">
      <img src="../assets/img/about/culture.png" alt="">
    </div>
    <div class="kong"></div>
    <div class="boss" ref="boss">
      <div class="title">创始团队</div>
      <div class="wu_img" :class="animateFlag1?'animate__animated animate__backInRight ':'none'"><img src="../assets/img/about/wu.png" alt=""></div>
      <div class="wu_text" :class="animateFlag1?'animate__animated animate__backInLeft ':'none'"><img src="../assets/img/about/wu_text.png" alt=""></div>
      <div class="zhou_img" :class="animateFlag1?'animate__animated animate__backInLeft  animate_yc_half_1':'none'"><img src="../assets/img/about/zhou.png" alt=""></div>
      <div class="zhou_text" :class="animateFlag1?'animate__animated animate__backInRight  animate_yc_half_1':'none'"><img src="../assets/img/about/zhou_text.png" alt=""></div>
      <div class="yang_img" :class="animateFlag2?'animate__animated animate__backInRight':'none'"><img src="../assets/img/about/yang.png" alt=""></div>
      <div class="yang_text" :class="animateFlag2?'animate__animated animate__backInLeft ':'none'"><img src="../assets/img/about/yang_text.png" alt=""></div>
    </div>
    <div class="kong"></div>
    <div class="honor" :class="activeNum ==0?'honor1':'honor2'">
      <div class="title">荣誉资质</div>
      <div class="tab">
        <div class="tab1" @click="changeTab(0)">
            <span>公司荣誉</span>
        </div>
        <div class="tab2" @click="changeTab(1)">
          <span>技术荣誉</span>
        </div>
        <div class="tab3" @click="changeTab(2)">
          <div class="img"><img src="../assets/img/about/tab.png" alt=""></div>
        </div>
        <div class="line"></div>
      </div>
      
        <div class="content content0" v-show="activeNum == 0" key="tab1">
          <div class="swiper-3" >
            <div class="swiper-wrapper" >
              <div class="swiper-slide" v-for="item in tabList1" :key="item">
                <img :src=" require('../assets/img/about/'+item)" alt="" >
              </div>
            </div>
          </div>
        </div>
        <div class="content content1" key="tab2" v-show="activeNum == 1" >
          <div class="swiper-4" >
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in tabList2" :key="item">
                <img :src=" require('../assets/img/about/'+item)" alt="" >
              </div>
            </div>
          </div>
        </div>
        <div class="content content1" v-show="activeNum == 2"  key="tab3">
          <div class="swiper-5" >
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in tabList3" :key="item">
                <img :src=" require('../assets/img/about/'+item)" alt="" >
              </div>
            </div>
          </div>
        </div>
      
    </div>
    <div class="lz">
        <div class="title"></div>
        <div class="lzBox">
          <div class="name">姓 名：</div>
          <div class="name_inp"><input type="text" v-model="name" placeholder="请输入您的姓名"/></div>
          <div class="phone">联系方式：</div>
          <div class="phone_inp"><input type="text" v-model="phone" placeholder="请输入您的联系方式" :maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" pattern="[0-9]*"/></div>
          <div class="btn fireworks" @click="sendMsg"><img src="../assets/img/index/btn.png" alt=""></div>
        </div>
    </div>
    <div class="map">
      <div class="title">联系我们</div>
      <div class="img" @click="goTo"><img src="../assets/img/about/map.png" alt=""></div>
      <div class="list">
        <div class="ico"><img src="../assets/img/about/ico1.png" alt=""></div>
        <div class="text">北京市海淀区上地信息路彩虹大厦北楼西203</div>
      </div>
      <div class="list">
        <div class="ico"><img src="../assets/img/about/ico2.png" alt=""></div>
        <div class="text">全国合作热线：400-611-1318</div>
      </div>
      <div class="list">
        <div class="ico"><img src="../assets/img/about/ico3.png" alt=""></div>
        <div class="text">全国客服热线：4008-017-018</div>
      </div>
      <!-- <baidu-map class="bm-view" :center="{lng: 116.314631, lat: 40.045445}" :zoom="15">
        <bm-marker :position="{lng: 116.314631, lat: 40.045445}" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
          <bm-label content="红本科技有限公司" :labelStyle="{color: 'red', fontSize : '12px',border:'none',background:''}" :offset="{width: -35, height: 30}"/>
        </bm-marker>
      </baidu-map> -->
    </div>
    <div class="kong"></div>
    <FooterText :pageStage="2"></FooterText>
    <!-- <div class="bottom">
        <p>版权所有：北京红本科技有限公司</p>
        <p>地址：北京市海淀区上地信息路11号彩虹大厦北楼西段203室</p>
        <p>电话：4008-017-018</p>
      </div> -->
  </div>
</template>
<script>
import Swiper,{Autoplay,Navigation, Pagination} from 'swiper'
Swiper.use([Autoplay,Navigation, Pagination])
import axios from 'axios'
import FooterText from './footerText.vue'
import { Toast } from 'vant';
import 'swiper/swiper-bundle.css'
import Header from "@/components/header";
export default {
  components: {
    Header ,
    FooterText,
  },
name:'about',
data(){
  return{
    topBtnShow:false,
    name:'',
    phone:'',
    activeName: 'second',
    index:0,
    photo1:['index1','index2','index3','index4'],
    yearList:[
      {
        id:0,
        year:'2001'
      },
      {
        id:1,
        year:'2010'
      },{
        id:2,
        year:'2011'
      },{
        id:3,
        year:'2012'

      },{
        id:4,
        year:'2013'
      },{
        id:5,
        year:'2014'
      },{
        id:6,
        year:'2015'
      },{
        id:7,
        year:'2016'
      },{
        id:8,
        year:'2017'
      },{
        id:9,
        year:'2018'
      },{
        id:10,
        year:'2019'
      },{
        id:11,
        year:'2020'
      },{
        id:12,
        year:'2021'
      },{
        id:13,
        year:'2022'
      },{
        id:14,
        year:'2023'
      }
    ],
    imgList_1:[
      {
        url:'about/img_2001.jpg',
        title:'“杨”帆起航  记忆引擎诞生',
        text:'杨宁远博士在美国斯坦福大学，带领“硅谷第三脑专家小组”成功研制“记忆引擎”技术，并成功运用到教育领域，推动人类学习进入智能学习时代。'
      },
      {
        url:'about/img_2010.jpg',
        title:'红杉树智能英语品牌创立',
        text:'首家红杉树智能英语体验中心在洛阳开业，1.0版本上线。红杉树智能英语产品研发中心在北京中关村成立。'
      },
      {
        url:'about/img_2011.jpg',
        title:'启动全国招商 进入连锁品牌时代',
        text:'启动全国招商会议。学习管理后台上线，方便学习管理。'
      },
      {
        url:'about/img_2012.jpg',
        title:'优化产品 召开首届全国合作伙伴年会',
        text:'召开红杉树智能英语功能研讨会，将产品的研发、规划、整合作为企业核心战略之一。在北京召开首届红杉树智能英语全国合作伙伴年会。'
      },
      {
        url:'about/img_2013.jpg',
        title:'召开表彰大会 开启爱苗工程公益活动',
        text:'召开红杉树智能英语全国明星会员表彰大会。开启“爱苗工程”公益活动，践行企业社会责任，将企业社会责任置于战略高度。'
      },
      {
        url:'about/img_2014.jpg',
        title:'品牌升级 开启运营服务新时代',
        text:'在河南洛阳成立全国市场运营管理服务中心，开启运营服务新时代。12月在红杉树智能英语全国合作伙伴年会上发布2.0版本，统一品牌标识，克莱曼正式登场。'
      },
      {
        url:'about/img_2015.jpg',
        title:'精诚精进 确定一年两次合作伙伴大会',
        text:'首届红杉树智能英语经验交流表彰大会在河南漯河召开，之后每年定期召开。'
      },
      {
        url:'about/img_2016.jpg',
        title:'全面启动课题班进校园',
        text:'加入中国智慧工程研究会，独创的“ECC极速提分教学模式”被列入“十三五”全国重点科研课题，把智能英语全面融入智慧校园建设。'
      },
      {
        url:'about/img_2017.jpg',
        title:'召开全国中小学校长智慧教育高峰论坛',
        text:'在北京、沈阳召开全国中小学校长智慧教育高峰论坛，红杉树智能英语进入公立学校，全面推进智慧校园的建设。在洛阳成立直营事业部，对外标准化建设进入新阶段。'
      },
      {
        url:'about/img_2018.jpg',
        title:'产品进入全面智能时代',
        text:'在南京召开第三届全国中小学校长智慧教育高峰论坛，加快华东区域智慧校园建设。红杉树智能英语会员佳绩频传，全国明星学员表彰大会在洛阳召开。'
      },
      {
        url:'about/img_2019.jpg',
        title:'小红本项目立项',
        text:'成功举办首届红杉树杯全国最快单词王争霸赛。为了能让乡镇、教育资源匮乏地区的孩子能够享用到智能化学习方式，小红本项目立项，启动研发。'
      },
      {
        url:'about/img_2020.jpg',
        title:'不限量免费发放疫期公益课 共同抗疫',
        text:'众志成城，抗击疫情，公司不限量免费发放疫期公益课提供给非红杉树智能英语会员的孩子，为疫病期间一心向学的孩子们提供一个好的学习渠道。'
      },
      {
        url:'about/img_2021.jpg',
        title:'小红本面向全国推广 全面转型',
        text:'818经验交流表彰大会小红本正式发布，面向全国推广。红杉树智能英语全面转型小红本。'
      },
      {
        url:'about/img_2022.jpg',
        title:'全面深化改革创新 坚定推进转型发展',
        text:'一年五场总部支付奖学金、奖品的大型营销活动，推动小红本事业快速发展。坚持自主研发生产小红本硬件，为智能英语学习定制硬件，只为提供最优学习体验。'
      },
      {
        url:'about/img_2023.jpg',
        title:'小红本“千城万店”计划开启 聚力创变再提速',
        text:'后双减时代小红本+督学服务+体验中心新运营模式成主流，小红本销量节节攀升。“千城万店”计划开启，小红本聚力创变再提速。'
      }
    ],
    mySwiper:null ,
    swiper2:null,
    bossHeight:0,
    animateFlag1:false,
    animateFlag2:false,
    activeNum:0,
    tabList1:['tab1_01.jpg','tab1_02.jpg','tab1_03.jpg','tab1_04.jpg','tab1_05.jpg'],
    tabList2: ['tab2_01.jpg','tab2_02.jpg','tab2_03.jpg','tab2_04.jpg','tab2_05.jpg','tab2_06.jpg','tab2_07.jpg','tab2_08.jpg','tab2_09.jpg'],
    tabList3: ['tab3_01.jpg','tab3_02.jpg','tab3_03.jpg','tab3_04.jpg','tab3_05.jpg','tab3_06.jpg','tab3_07.jpg']
  }
},
created(){
  
},  
mounted(){
    if (!this._isMobile()) {
      // 跳转至 pc 端路由
      // this.$router.push({
      //   path: '/'
      // })
      let app = document.querySelector('#app');
      app.style.width = "750px";
      app.classList.add("pcStyle");
    }
  let _this = this
    //挂在后的去初始化swiper
    // new Swiper ('.swiper', {
    //   direction: 'horizontal', // 垂直切换选项
    //   loop: true, // 循环模式选项
      
    //   // 如果需要分页器
    //   pagination: {
    //     el: '.swiper-pagination',
    //     click:true,
    //   },
    //   autoplay: {
    //   delay: 2000,
    //   stopOnLastSlide: false,
    //   disableOnInteraction: true,
    //   },
      
    //   // 如果需要滚动条
    //   // scrollbar: {
    //   //   el: '.swiper-scrollbar',
    //   // },
    // })   
    this.swiper2 = new Swiper(".swiper-2", {
        direction: "horizontal", // 垂直切换选项
        loop: true, // 循环模式选项
        loopAdditionalSlides: 4,

        // 如果需要分页器
        pagination: {
          el: ".swiper-2-pagination",
          click: true,
        },
        navigation: {
          nextEl: ".swiper-2-button-next",
          prevEl: ".swiper-2-button-prev",
        },
        on:{
          slideChangeTransitionEnd:function(e){
            // _this.mySwiper.slideTo(this.activeIndex);
            _this.$nextTick(()=>{
              _this.mySwiper.slideTo(this.activeIndex);
            })
          console.log(this.activeIndex);
        }
        }
        // autoplay: {
        //   delay: 2000,
        //   stopOnLastSlide: false,
        //   disableOnInteraction: true,
        // },

        // 如果需要滚动条
        // scrollbar: {
        //   el: '.swiper-scrollbar',
        // },
      });
      this.mySwiper =  new Swiper(".swiper-container1", {
      slidesPerView: 5, //设置slider容器能够同时显示的slides数量
      spaceBetween: 22, //slide之间的距离（单位px）
      centeredSlides: true, //设定为true时，active slide会居中，而不是默认状态下的居左。
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true, //修改swiper的父元素时，自动初始化swiper
      loop: true,
      loopPreventsSlide: true,//默认true，阻止
     
      // swiper点击事件
      on: {
        // this.clickedSlide.dataset.swiperSlideIndex
        click: function (e) {
          console.log(e,this.clickedSlide.dataset.swiperSlideIndex);
          if(e.clickedIndex == 22){
            _this.mySwiper.slideNext();
            _this.swiper2.slideNext();
          }else if(e.clickedIndex == 2){
            _this.mySwiper.slidePrev()
            _this.swiper2.slidePrev();
            
          }else{
            _this.mySwiper.slideTo(e.clickedIndex);
            _this.swiper2.slideTo(e.clickedIndex);
          }
          // _this.swiper2.slideTo(this.clickedSlide.dataset.swiperSlideIndex)
        },
        slideChangeTransitionEnd:function(e){
          _this.swiper2.slideTo(this.activeIndex);
          console.log(this.activeIndex);
        }
      },
    });
      let swiper3 = new Swiper(".swiper-3", {
        direction: "horizontal" /*横向滑动*/,
        centeredSlides: true,
        spaceBetween: 30,
        slidesPerView : 'auto',
        loopedSlides: 3,
        autoplay: true,
        loop: true,
        // tab切换失效bug解决
        observer: true,
        observeParents: true,
       
      });
     
      let swiper4 = new Swiper(".swiper-4", {
        direction: "horizontal" /*横向滑动*/,
        centeredSlides: true,
        spaceBetween: 30,
        slidesPerView : 'auto',
        loopedSlides: 3,
        // autoplay: true,
        loop: true,
        // tab切换失效bug解决
        observer: true,
        observeParents: true,
       
      });
      let swiper5 = new Swiper(".swiper-5", {
        direction: "horizontal" /*横向滑动*/,
        centeredSlides: true,
        spaceBetween: 30,
        slidesPerView : 'auto',
        loopedSlides: 3,
        // autoplay: true,
        loop: true,
        // tab切换失效bug解决
        observer: true,
        observeParents: true,
       
      });
      setInterval(() => {
        swiper4.slideNext();
        swiper5.slideNext();
      }, 3000)
    this.bossHeight = this.$refs.boss.offsetTop
    window.addEventListener('scroll', this.handleTabFix, true)   
      
  },
  methods:{
    sendMsg() {
      if(this.name && this.phone){
        let num_reg = /^13[0-9]{1}[0-9]{8}$|15[0-9]{1}[0-9]{8}$|16[0-9]{1}[0-9]{8}$|18[0-9]{1}[0-9]{8}|17[0-9]{1}[0-9]{8}$|19[0-9]{1}[0-9]{8}$|14[0-9]{1}[0-9]{8}/;
        if(!num_reg.test(this.phone)){
          Toast('请填写正确的手机号');
          return false;
        }
        if(this.name.length>10){
          Toast('姓名不可以大于10位');
          return false;
        }
        let data = {
          name: this.name,
          phone: this.phone,
          msg:"关于我们"
        };
        let formData = new FormData();
        for (let dataKey in data) {
          formData.append(dataKey, data[dataKey]);
        }
        let url= ""
        if (process.env.NODE_ENV == "production") {
          //生产环境
          url = "https://api.xiaohongben888.com/product/submitProductInquiry"
        }else{
          //本地环境
          url = "/api/product/submitProductInquiry"
        }

        axios.post(url, formData).then(function (response) {
          console.log(response);
          if(response.data.code==200){
            Toast('提交成功,项目经理将在24小时内与您联系。');
          }
        }).catch(function (error) {
          console.log(error);
        });
      }else {
        Toast('请填写完整信息');
      }
    },
    goTo(){
      window.location.href = 'http://api.map.baidu.com/marker?location=116.314631,40.045445&title=北京红本科技有限公司&content=北京市海淀区上地信息路11号彩虹大厦北楼西段203室&output=html&src=webapp.baidu.openAPIdemo'
    },
    scrollToTop(){
      // this.$refs.about.scrollTo({top: 0, behavior: 'smooth'});
      this.$refs.about.scrollTo({top: 0});
    },
    handleTabFix() {
            // var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      let scrollTop;
      if(this.$refs.about){
        scrollTop = this.$refs.about.scrollTop;
      }
      let height = window.innerHeight;
      if(scrollTop>height){
        this.topBtnShow = true;
      }else {
        this.topBtnShow = false;
      }
          //动画1
          if(scrollTop > this.bossHeight-300){
              this.animateFlag1 = true
            }
            if(scrollTop > this.bossHeight + 200){
              this.animateFlag2 = true
            }
    },
    next(){
      this.swiper2.slideNext();
      this.mySwiper.slideNext();
    },
    prev(){
      this.swiper2.slidePrev();
      this.mySwiper.slidePrev();
    },
    handleClick(tab, event) {
        console.log(tab, event);
    },
    changeTab(num){
      let tabLeft = document.querySelector(`.tab${num+1}`).offsetLeft;
      // document.querySelector('.line').style.left = num * 106 +'px'
      document.querySelector('.line').style.left = tabLeft +'px'
      this.activeNum = num
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    changeYear(num){
      console.log(num,'num');
      this.mySwiper.slideTo(num, 1000, false);
    }

  }
}
</script>

<style lang="scss" scoped>
div,span,p{
  font-family: HarmonyOS Sans SC;
}
.fireworks {
    animation: pulse 0.5s infinite;
}
 
@keyframes fireworks {
    0% {
        transform: scale(1);
        /*开始为原始大小*/
    }
 
    25% {
        transform: scale(1.3);
        /*放大1.1倍*/
    }
 
    50% {
        transform: scale(1);
    }
 
    75% {
        transform: scale(1.3);
    }
 
}
.swiper-slide{
  width: 100%;
  img{
    margin: 0 auto;
  }
}
.van-toast__text{
  color: #fff;
}
.BMapLabel{
  background: '' !important;
  border: none !important;
}
.kong{
height: 6px;
background-color: #f3f3f3;
}

body{
background: #ccc !important;
}
.toTop {
  position: fixed;
  right:20px;
  bottom:150px;
  z-index: 10;
  width:50px;
  height:50px;
  opacity: 0.7;
  background: url("../assets/img/project/top.png") round;
}
  .about{
    height:100vh;
    overflow: auto;
    overflow-x: hidden;
    // padding-top: 40px;
    width: 100%;
    // overflow: hidden;
    >.top{
      padding: 0 20px 13.5px 23.5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left{
        width: 195.5px;
        height: 29.5px;
        font-size: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .right{
        width: 24px;
        height: 14.5px;
        font-size: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .nav{
      width: 100%;
      height: 162px;
      box-shadow: 2.5px -0.5px 5px 1.5px #a5a5a52e;
      background-color: #fff;
      // margin-top: 8.5px;
      display: flex;
      padding: 41.5px 28.5px;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      .nav_item1{
        
        .img{
          width: 38.5px;
          height: 34.5px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          font-size: 16.5px;
          text-align: center;
          color: #333333 100%;
        }
      }
      .nav_item2{
        .img{
          width: 36.5px;
          height: 36.5px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          font-size: 16.5px;
          text-align: center;
          color: #333333 100%;
        }
      }
      .nav_item3{
        .img{
          width: 36.5px;
          height: 36.5px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          font-size: 16.5px;
          text-align: center;
          color: #333333 100%;
        }
      }
      .nav_item4{
        .img{
          width: 48.5px;
          height: 32px;
          margin: 0 auto;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .text{
          font-size: 16.5px;
          text-align: center;
          color: #333333 100%;
        }
      }
    }
    .about_detail{
      width: 100%;
      height: 1220px;
      padding-top: 59px;
      box-sizing: border-box;
      .title{
        font-weight: 700;
        font-size: 25px;
        text-align: center;
        color: #DE041C;
      }
      .icons{
        width: 315px;
        height: 223px;
        margin: 0 auto;
        margin-top: 41.5px;
        img{
          width: 100%;
          height: 100%;
        };
      }
      .text1{
        .text_tit{
          display: flex;
          align-items: center;
          margin-top: 32px;
          margin-left: 30px;
          .tip{
            width: 24.5px;
            height: 18.5px;
            font-size: 0;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .txt{
            margin-left: 7px;
            font-weight: 700;
            font-size: 16.5px;
            color: #333333;
          }
        }
        .text_box{
          width: 322.5px;
          height: 251px;
          margin: 0 auto;
          margin-top: 16px;
          box-shadow: 0 0 7px 0 #6e6e6e5c;
          padding: 10px;
          box-sizing: border-box;
          overflow-y: auto;
          overflow: scroll;
          p {
            text-indent: 2em;
            font-weight: 500;
            font-size: 14px;
            color: #999999;
            line-height: 29.9px;
            text-align: left;
          }
        }
      }
      .text2{
        .text_tit{
          display: flex;
          align-items: center;
          margin-top: 32px;
          margin-left: 30px;
          .tip{
            width: 24.5px;
            height: 18.5px;
            font-size: 0;
            img{
              width: 100%;
              height: 100%;
            }
          }
          .txt{
            margin-left: 7px;
            font-weight: 700;
            font-size: 16.5px;
            color: #333333;
          }
        }
        .text_box{
          width: 100%;
          height: 445px;
          font-size: 0;
          margin-top: 16px;
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .all_year{
      width: 100%;
      height: 675px;
      padding-top: 56px;
      box-sizing: border-box;
      position: relative;
      .title{
        font-weight: 700;
        font-size: 25px;
        text-align: center;
        color: #DE041C;
      }
      .top{
        width: 90%;
        overflow: hidden;
        padding: 30px 0;
        margin: 0 auto;
        box-sizing: border-box;
        line-height: 20px;
        .swiper-container1{
          width: 100%;
          height: 20px;
        }
        .num,.swiper-slide{
          font-weight: 500;
          font-size: 17.78px;
          color: #999999;
        }
        .swiper-slide::before{
          content:' ';
          width: 4px;
          height: 7px;
          background: url('../assets/img/about/jiantou.png') no-repeat;
          background-size: 4px 7px;
          font-size: 12px;
          color: #999;
          position: absolute;
          left: -15px;
          top: 6px;
        }
        .swiper-slide-active{
          width: 65.5px !important;
          height: 20px;
          line-height: 20px;
          border-radius: 10.75px 10.75px 10.75px 10.75px;
          background: #dc1617;
          color: #FFFFFF;
          text-align: center;
        }
      }
    }
    .content{
      position: relative;
      .swiper-2 {
      position: relative;
      width: 375px;
      height: 600px;
      --swiper-theme-color: #999999;
      --swiper-pagination-color: #df0522;
      .swiper-slide {
        width: 375px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        img {
          width: 375px;
          height: 244px;
          margin-bottom: 32px;
        }
        .swiper_tit{
          font-weight: 700;
          font-size: 17.5px;
          text-align: center;
          color: #999999;
          margin-bottom: 22px;
        }
        .swiper-2-text {
          font-weight: 400;
          text-indent: 2em;
          width: 291px;
          text-align: left;
          // height: 113.5px;
          font-size: 14px;
          line-height: 25px;
          color: #999;
        }
      }
      // .swiper-2-pagination {
      //   position: absolute;
      //   bottom: 10px;
      //   left: 50%;
      //   transform: translate3d(-50%, 0, 0);
      // }
      // .swiper-2-button-next {
      //   &:after {
      //     content: "";
      //   }
      //   width: 33px;
      //   height: 7px;
      //   background: url("../assets/img/coreTechnologies/swiper-next.png") round;
      //   position: absolute;
      //   top: auto;
      //   bottom: 20px;
      //   right: 28px;
      // }

      // .swiper-2-button-prev {
      //   &:after {
      //     content: "";
      //   }
      //   width: 33px;
      //   height: 7px;
      //   background: url("../assets/img/coreTechnologies/swiper-prev.png") round;
      //   position: absolute;
      //   top: auto;
      //   bottom: 20px;
      //   left: 28px;
      // }
    }
   
    }
    .culture{
      width: 100%;
      height: 680px;
      font-size: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .boss{
      width: 100%;
      height: 1080px;
      background: url('../assets/img/about/boss_bg.png');
      background-size: 100% 1089px;
      padding-top: 59px;
      padding-left: 28.5px;
      box-sizing: border-box;
      position: relative;
      .title{
        text-align: left;
        font-weight: 700;
        font-size: 25px;
        color: #DF0522;
      }
      .wu_img{
        width: 231px;
        // height: 308px;
        position: absolute;
        top: 91px;
        right: -22px;
        font-size: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .wu_text{
        width: 336.5px;
        height: 86px;
        font-size: 0;
        position: absolute;
        top: 271px;
        left: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .zhou_img{
        width: 233px;
        height: 312px;
        font-size: 0;
        position: absolute;
        top: 396px;
        left: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .zhou_text{
        width: 339.5px;
        height: 101.5px;
        font-size: 0;
        position: absolute;
        top: 562px;
        right: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .yang_img{
        width: 228px;
        height: 285px;
        font-size: 0;
        position: absolute;
        top: 702px;
        right: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .yang_text{
        width: 336px;
        height: 86px;
        font-size: 0;
        position: absolute;
        top: 882px;
        left: 0;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .honor{
      width: 100%;
      // height: 518px;
      padding-top: 59px;
      box-sizing: border-box;
      .title{
        font-weight: 700;
        font-size: 25px;
        text-align: center;
        color: #DF0522;
      }
      .tab{
        width: 316px;
        display: flex;
        align-items: center;
        margin: 0 auto;
        margin-top: 32px;
        position: relative;
        div{
          width: 106px;
          height: 23px;
          line-height: 23px;
          text-align: center;
          font-weight: 600;
          font-size: 16.5px;
          color: #333333;
          
        }
        .tab3{
          .img{
            width: 86px;
            height: 20px;
            font-size: 0;
            margin: 0 auto;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
        .line{
          position: absolute;
          width: 106.19px;
          height: 3.67px;
          background: #df0522;
          left: 0;
          bottom: -8px;
          transition: all 0.3s linear;
        }
      }
      .content{
        margin-top: 50px;
      }
      .content0{
        .swiper-3{
          width: 100%;
          .swiper-slide{
            width: 249px;
            height: 186px;
            img {
                  width: 249px;
                  height: 186px;
                }
          }
        }
      }
      .content1{
        .swiper-4{
          width: 100%;
          .swiper-slide{
            width: 228.5px;
            height: 316px;
            img {
                  width: 228.5px;
                  height: 316px;
                }
          }
        }
        .swiper-5{
          width: 100%;
          .swiper-slide{
            width: 228.5px;
            height: 316px;
            img {
                  width: 228.5px;
                  height: 316px;
                }
          }
        }
      }
    }
    .honor1{
      height: 418px;
    }
    .honor2{
      height: 518px;
    }
    .lz{
      background-color: #f4f8f9;
      height: 430px;
        .title{
          width: 100%;
          height: 195px;
          background: url('../assets/img/about/shiyong.png');
          background-size: 100% 195px;
        }
        .lzBox{
          width: 346.66px;
          height: 269.02px;
          border-radius: 5px 5px 5px 5px;
          background: #ffffff;
          box-shadow: 0 2.5px 5px 1.5px #a5a5a52e;
          margin: 0 auto;
          margin-top: -52px;
          padding: 30px 25px;
          box-sizing: border-box;
          .name{
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            text-align: left;
          }
          .name_inp{
            margin-top: 9px;
            input{
              width: 296px;
              height: 37px;
              border: 1px solid #999;
              border-radius: 5px;
              box-sizing: border-box;
              padding-left: 14px;
              font-size: 14px;
            }
          }
          .phone{
            font-weight: 500;
            font-size: 14px;
            color: #333333;
            text-align: left;
            margin-top: 21px;
          }
          .phone_inp{
            margin-top: 9px;
            input{
              width: 296px;
              height: 37px;
              border: 1px solid #999;
              border-radius: 5px;
              box-sizing: border-box;
              padding-left: 14px;
              font-size: 14px;
            }
          }
          .btn{
            width: 296px;
            height: 37px;
            font-size: 0;
            margin: 0 auto;
            margin-top: 25px;
            img{
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .map{
        width: 100%;
        height: 472px;
        padding-top: 35px;
        box-sizing: border-box;
        .title{
          font-weight: 700;
          font-size: 25px;
          text-align: center;
          color: #DF0522;
        }
        .img{
          width: 320px;
          height: 224px;
          font-size: 0;
          margin: 0 auto;
          margin-top: 17px;
          margin-bottom: 14px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .list{
          display: flex;
          align-items: center;
          margin-left: 28px;
          margin-top: 9px;
         .ico{
          width: 35px;
          height: 35px;
          font-size: 0;
          img{
            width: 100%;
            height: 100%;
          }
         }
         .text{
          width: 284px;
          height: 35px;
          border-radius: 0px 5px 5px 0px;
          background: #f0f0f0;
          font-weight: 500;
          font-size: 13.5px;
          text-align: left;
          line-height: 35px;
          color: #333333 100%;
          padding-left: 10px;
          box-sizing: border-box;
         }
        }
        .bm-view{
          width: 319px;
          height: 224px;
        }
      }
      .bottom{
      padding: 36px 0;
      background-color: #f5f9fa;
        p{
          color: #999999;
          font-weight: 400;
          font-size: 11.5px;
          text-align: center;
          line-height: 20.09px;
        }
      }
  }
  .next{
      position: absolute;
      right: 40px;
      bottom: 20px;
      z-index: 999999;
      width: 33.5px;
      height: 7.5px;
      font-size: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .prev{
      position: absolute;
      left: 40px;
      bottom: 20px;
      z-index: 999999;
      width: 33.5px;
      height: 7.5px;
      font-size: 0;
      img{
        width: 100%;
        height: 100%;
      }
    }
.swiper {
    width: 375px;
    height: 293px;

  --swiper-pagination-color: #fff;/* 两种都可以 */
    .swiper-slide{
      width: 375px;
      height: 293px;
    img{
      width: 100%;
      height: 100%;
    }
    }
} 
.none{
  display: none;
}
:root {
  --animate-duration: 1s;
}
.animate_yc_half_1{
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
.animate_yc_half_2{
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}
.animate_yc_half_3{
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  animation-delay: 1.5s;
  --animate-delay: 1.5s;
}
</style>
<style lang="scss">
.pcStyle{
  // width:750px;
  position: relative;
  margin:0 auto;
}
.van-toast  {
  .van-toast__text {
    color: #fff;

  }
}
</style>